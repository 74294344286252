/**
 * All web routes within the app are handled at the frontend by vue.js. Use this
 * file to register all routes required within the app.
 */
import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

/**
 * Define all the routes used throughout the application.
 */
const routes = [
    {
        path: '/:referralCode',
        name: 'ReferralRedirect',
        beforeEnter: (to, from, next) => {
            const { referralCode } = to.params;
            if (referralCode && /^[A-Z0-9]{8}$/.test(referralCode)) {
                localStorage.setItem('referralCode', referralCode);
            }
            next('/');
        }
    },
    {
        path: '/',
        name: 'Welcome',
        component: () => import(/* WebpackChunkName: "welcome" */ './views/Welcome'),
        meta: { preload: true }
    },
    {
        path: '/your-space',
        name: 'Your Space',
        component: () => import(/* WebpackChunkName: "settings" */ './views/YourSpace'),
        meta: { preload: true, requiresAuth: true }
    },
    {
        path: '/greystar',
        name: 'Greystar',
        component: () => import(
            /* WebpackChunkName: "settings" */ './views/partners/Greystar'),
        meta: { preload: true }
    },
    {
        path: '/serendipity-labs',
        name: 'SerendipityLabs',
        component: () => import(
            /* WebpackChunkName: "settings" */ './views/partners/SerendipityLabs'),
        meta: { preload: true }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* WebpackChunkName: "settings" */ './views/Notifications'),
        meta: { preload: true, requiresAuth: true }
    },
    {
        path: '/address-book',
        name: 'Address Book',
        component: () => import(/* WebpackChunkName: "settings" */ './views/AddressBook'),
        meta: { preload: true, requiresAuth: true }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* WebpackChunkName: "settings" */ './views/Settings'),
        meta: { preload: true, requiresAuth: true },
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: () => import(
                    /* WebpackChunkName: "settings" */ './components/settings/Profile')
            },
            {
                path: 'referral',
                name: 'Invite Friends',
                component: () => import(
                    /* WebpackChunkName: "settings" */ './components/settings/Referral.vue')
            },
            {
                path: 'payment-methods',
                name: 'Payment Methods',
                component: () => import(
                    /* WebpackChunkName: "settings" */ './components/settings/PaymentMethods')
            },
            {
                path: 'deactivate-account',
                name: 'Deactivate Account',
                component: () => import(
                    /* WebpackChunkName: "settings" */ './components/settings/DeactivateAccount')
            }
        ]
    },
    {
        path: '/checkout',
        name: 'FastOrdering',
        component: () => import(
            /* WebpackChunkName: "fast-ordering" */ './views/FastOrdering.vue'),
        meta: { preload: true }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: () => import(/* WebpackChunkName: "not-found" */ './views/NotFound'),
        meta: { preventCrawl: true }
    }
];

export default createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: '',
    linkExactActiveClass: 'active',
    scrollBehavior: to => to.hash ? { el: to.hash } : { top: 0, left: 0 }
});
