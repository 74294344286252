import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with necessary plugins
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/*
 * Set default behavior to use the local timezone
 * This will automatically use the browser's timezone
 */
dayjs.tz.guess();

export default dayjs;
